import { apiURL } from "constant";
import { qatarToken } from "constant";
// import { token } from "constant";
import React, { useEffect, useState } from "react";
import { createContext } from "react";

export const DataContextQatar = createContext({});

export default function DataContainerQatar({ children }) {
  const [data, setData] = useState({
    agenda: [],
    speakers: [],
    speakersRiyadh: [],
    sponsors: [],
    sponsorsRiyadh: [],
  });

  useEffect(() => {
    fetch(`${apiURL}assets/qatar/eventData.json`, {
      method: "get",
      headers: new Headers({
        Authorization: qatarToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setData({
          speakers: data.speakers,
          agenda: data.agenda,
          sponsors: data.sponsors,
          speakersRiyadh: data.speakersRiyadh,
          sponsorsRiyadh: data.sponsorsRiyadh,
          speakersDubai2024: data.speakersDubai2024,
          sponsorsDubai2024: data.sponsorsDubai2024,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return <DataContextQatar.Provider value={{ ...data }}>{children}</DataContextQatar.Provider>;
}
