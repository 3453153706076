// reactstrap components
import { Container, Col, Row, Button, Card } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundColor: "transparent",
          backgroundImage: `url(${require("assets/images/creative.png")})`,
          backgroundSize: size ? "100%" : "cover",
          backgroundRepeat: "no-repeat",

          // marginTop: size ? "7.8rem" : "0rem",
          paddingBottom: size ? "8.2rem" : "10rem",
        }}
        data-parallax={true}
      >
        {/* <div className="overlay-primary"></div> */}

        <Container>
          <Row className="justify-content-start">
            <Col md={6} xs={12} className="text-left">
              <img
                src="/assets/dubai/bitDubai2024.png"
                width="100%"
                alt="main logo"
                className="pt-5"
                // style={{ maxWidth: 400 }}
              />
              <h3 className="text-dark text-400 mt-2 text-right">
                12<sup>th</sup>, 13<sup>th</sup> & 14<sup>th</sup> November | Dubai, UAE
              </h3>

              <Container fluid className="pt-3">
                <Row>
                  <Col lg={12} className="pb-3 px-0 text-right">
                    <Button
                      href="/dubai/register"
                      className="navbar-item-custom text-400 text-dark rounded-0 "
                      style={{
                        backgroundImage: "linear-gradient(315deg, #639ccc 0%, #000000 96%)",
                        border: "2px solid #fff",
                        width: size && "50%",
                      }}
                      size="lg"
                    >
                      <span
                        style={{
                          color: "#fff",
                          textTransform: "none",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                        className="text-600"
                      >
                        REGISTER NOW <i class="fa fa-arrow-right" />
                      </span>
                    </Button>
                    <Button
                      href="/dubai/sponsor"
                      className="navbar-item-custom text-400 text-dark rounded-0"
                      style={{
                        backgroundImage: "linear-gradient(315deg, #639ccc 0%, #000000 96%)",
                        border: "2px solid #ffffff",
                        width: size && "50%",
                      }}
                      size="lg"
                    >
                      <span
                        style={{
                          color: "#fff",
                          textTransform: "none",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                        className="text-600"
                      >
                        BECOME A SPONSOR
                        <i class="fa fa-arrow-right" />
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="d-none" style={{ marginTop: size && "-10rem" }}>
        <Col lg={4} xs={12} className={"ml-auto mr-auto pb-3"}>
          <h3 className="text-400 text-dark text-center mb-3"> Co - Presented By</h3>
          <Card className="rounded-0">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <img
                    src={require("assets/images/weValue.png")}
                    alt={"wevalue"}
                    width="100%"
                    className="rounded-0"
                  />
                </Col>
              </Row>
            </Container>
          </Card>
        </Col>
      </Container>
    </>
  );
}

export default LandingPageHeader;
