import React, { useContext } from "react";

import { Container, Row, Col, Card } from "reactstrap";
import { DataContextQatar } from "DataContainerQatar";
import { groupBy } from "views/content";

function Sponsor() {
  const { sponsorsRiyadh: allSponsors } = useContext(DataContextQatar);
  const rows = groupBy("panel")(allSponsors);
  if (Object.keys(rows).length < 1) {
    return null;
  }
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-300 text-uppercase text-center text-dark mb-5">
                2024 Partners
              </h1>
            </Col>
          </Row>
          {Object.keys(rows).map((r) => (
            <Row>
              {rows[r].map((s) => (
                <Col lg={s.size} xs={6} className={"ml-auto mr-auto pb-3"}>
                  <a
                    href={s.url}
                    style={{
                      display: 'block',
                      // border: '1px solid #dedede',
                    }}
                  >
                    <Card className="rounded-0">
                      <Container fluid>
                        <h5
                          className="text-400 text-dark text-center mb-3"
                          style={{
                            marginTop: '25px',
                            minHeight: '3.5rem',
                            fontWeight: '600',
                          }}
                        >
                            {s.type}
                        </h5>
                        <img src={s.image} alt={s.name} width="100%" className="rounded-0 pb-3" />
                      </Container>
                    </Card>
                    {/* <Card className="rounded-0">
                      <Container fluid>
                        <Row>
                          <Col lg={12}>
                            
                          </Col>
                        </Row>
                      </Container>
                    </Card> */}
                  </a>
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
