import React from "react";
import { Container, Row, Col, Button, Card, CardImg } from "reactstrap";
import './riyadh.css'

import "react-circular-progressbar/dist/styles.css";
// import SpeakerCard from "./SpeakerCard";

const styleKeyFind = {
  fontSize: '1.2rem',
  lineHeight: '2rem',
  background: 'rgb(81, 127, 166)',
  color: '#fff',
  padding: '15px 20px',
  marginBottom: '25px',
  borderRadius: '10px',
}

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
      document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const mdSize =
    document.body.clientWidth >= 1324 ||
      document.documentElement.clientWidth >= 1324
      ? true
      : false;

  return (
    <>
      <section id="about">
        <div
          style={{
            background: '#002f3c',
            /* backgroundImage:
              size && `url(${require("assets/images/overview-bg.jpg")})`,
            backgroundSize: size ? "contain" : "contain",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed", */
          }}
          className="section py-0"
          /* data-parallax={true} */
          ref={pageHeader}
        >
          <Container fluid>
            <Row className="justify-content-left align-items-center">
              <Col md={6} xs={12}>
                <div
                  className="text-white"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 4rem 1rem",
                  }}
                >
                  <h1
                    className="header-text text-left"
                    style={{ fontSize: size && "5rem" }}
                  >
                    OVERVIEW
                  </h1>
                  <br />
                  <br />
                  <p className="text-400 text-left mb-4">
                    The last two years have propelled the world into an era of
                    unparalleled digital transformation in banking and fintech.
                    What was once perceived as a temporary disruption has
                    evolved into an unstoppable force, shaping the new world
                    order. As we stand on the precipice of this transformative
                    journey, digitization emerges as the linchpin of virtually
                    every industry. In this dynamic landscape, fintech
                    innovations are not merely meeting evolving customer
                    expectations; they are redefining the very concepts of
                    customers and currency.
                  </p>
                  <p className="text-400 text-left">
                    Saudi Vision 2030 is crucial to these developments. This
                    initiative aims to diversify the economy, foster a
                    supportive business environment, and invest in education.
                    It promotes non-oil industries and entrepreneurship,
                    aligning with the rapid digital transformation in banking
                    and fintech, ensuring economic resilience and innovation
                  </p>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <img src={require("assets/images/overview-bg.jpg")} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url(${require("assets/images/61769.jpg")})`,
            backgroundSize: size ? "100%" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <Container>
            <Row>
              <Col md={12} xs={12}>
                <div
                  className="text-dark"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 3rem 1rem",
                  }}
                >
                  <h1
                    className="header-text text-center"
                    style={{ fontSize: size && "3rem" }}
                  >
                    Key Findings & Statistics
                  </h1>
                  <div className="keyfindArea">
                    <div className="boxOne">
                      The Net Interest Income for digital banks in Saudi Arabia is projected to reach US$7.64 billion by 2024, highlighting significant growth in the digital banking sector.
                    </div>
                    <div className="boxOne">
                      The digital banking market is expected to see a steady annual growth rate of 6.16% from 2024 to 2029, resulting in a market volume of US$10.30 billion by the end of 2029.
                    </div>
                    <div className="boxOne">
                      There has been a significant surge in the adoption of digital banking services in Saudi Arabia, driven by consumer demand and technological advancements.
                    </div>
                    <div className="boxOne">
                      Traditional banks in Saudi Arabia are investing heavily in digital transformation to cater to the growing demand for digital banking services.
                    </div>
                    <div className="boxOne">
                      The embedded finance industry in Saudi Arabia is expected to grow by 30.9% annually, reaching US$5.76 billion in 2024. This growth indicates a robust integration of financial services into non-financial platforms.
                    </div>
                    <div className="boxOne">
                      The total assets of the Saudi banking sector are expected to continue their robust growth trajectory in 2024, contributing to the overall stability and growth of the financial market.
                    </div>
                    <div className="boxOne">
                      Non-oil growth in Saudi Arabia is projected at about 3.5% in 2024, partially driven by investment in the financial sector, including digital banking and embedded finance.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: "transparent",
            backgroundImage: `url(${require("assets/images/qatar.jpeg")})`,
            backgroundSize: size ? "100%" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <Container>
            <Row className="justify-content-start">
              <Col md={12} xs={12}>
                <div
                  className="text-white"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 4rem 1rem",
                  }}
                >
                  <h3 className="py-3">Key Highlights:</h3>
                  <h5 className="text-400  text-left">
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          Digital Banking Landscape:
                        </span>
                        Saudi Arabia rapidly adopts digital banking due to
                        government initiatives and changing consumer
                        preferences.
                      </li>
                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          Open Banking:
                        </span>
                        Initiatives foster innovation, improve financial
                        services, and promote financial inclusion. Saudi Arabia
                        is experiencing a 30% surge in fintech adoption rates
                        due to these initiatives
                      </li>
                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          Fintech Integration:
                        </span>
                        The fintech sector is expanding with increasing
                        investments and regulatory support, particularly through
                        Vision 2030, which aims to diversify the economy and
                        enhance financial technology.
                      </li>

                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          Investment Opportunities:
                        </span>
                        Vision 2030 has paved the way for new investment
                        opportunities, particularly in fintech, by
                        revolutionizing traditional financial services and
                        promoting innovative investment strategies.
                      </li>
                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          Neobanks and Digital Banks:
                        </span>
                        The emergence of neobanks and digital banks is
                        transforming the Saudi banking sector, offering
                        competitive and innovative financial services that cater
                        to modern consumer needs.
                      </li>
                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          Regulatory Evolution:
                        </span>
                        Saudi Arabia's regulatory landscape is evolving to
                        support the fintech boom, with SAMA playing a crucial
                        role in establishing a conducive environment for digital
                        transformation.
                      </li>
                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          Global Positioning:
                        </span>
                        Vision 2030 is positioning Saudi Arabia as a global
                        economic powerhouse by leveraging fintech to drive
                        economic growth and diversification.
                      </li>
                    </ul>
                    <br />
                    <br />
                  </h5>
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section" style={{ backgroundColor: "#fff" }}>
          <Container>
            <Row>
              <Col md={12} xs={12}>
                <div
                  className="text-dark"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 3rem 1rem",
                  }}
                >
                  <h1
                    className="header-text text-center"
                    style={{ fontSize: size && "3rem" }}
                  >
                    Who will be Attending?
                  </h1>
                  <div className="attendArea">
                    <div className="boxOne">
                      CEOs, COOs, CTOs, CIOs, and Managing Directors of Leading Fintech & Banking Companies
                    </div>
                    <div className="boxOne">
                      SVP/VP/AVP/GM - Digital Transformation, Retail Banking, Wholesale Banking, SME Banking, Customer Experience
                    </div>
                    <div className="boxOne">
                      Heads of Product Development, Digital Transformation, Innovation, Strategy, and Partnerships in Fintech & Banking
                    </div>
                    <div className="boxOne">
                      Heads of Retail Banking/Mobile Banking/Consumer Banking/Cards & Payments
                    </div>
                    <div className="boxOne">
                      Directors, General Managers, and Deputy Managers in Fintech & Banking
                    </div>
                    <div className="boxOne">
                      Fintech Specialists, Experts, Team Leads, Evangelists, and Tech Enthusiasts
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <div
          className="section"
          style={{
            backgroundColor: "transparent",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <Container>
            <h1
              className="header-text text-left mb-4"
              style={{ fontSize: size ? "3rem" : "2rem" }}
            >
              Primary Industries: <br />
            </h1>
            <Row className="justify-content-start">
              {content.map((c) =>
                c.type === "IMAGE" ? (
                  <Col lg={6}>
                    {/* <img
                      src={require("assets/images/qatarstats.png")}
                      alt={"wevalue"}
                      width="100%"
                      style={{ maxWidth: 300 }}
                      className="rounded-0"
                    />
                    <h5 className="text-600 mt-2">
                      <a className="text-600" href={c.source}>
                        Source
                      </a>
                    </h5> */}
        {/* </Col>
                ) : (
                  <Col md={c.grid} xs={12}>
                    <div
                      className="text-dark"
                    >
                      <h5 className="text-600 mt-2">{c.title}</h5>
                      <p className="text-400  text-left"></p>
                      <br />
                    </div>
                  </Col>
                )
              )}
            </Row>
          </Container>
        </div> */}

        <div className="section bg_trends" style={{
          backgroundImage: `url(${require("assets/images/qatar.jpeg")})`,
          backgroundSize: size ? "100%" : "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}>
          <Container>
            <Row>
              <Col md={12} xs={12}>
                <div
                  className="text-white"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 3rem 1rem",
                  }}
                >
                  <h1
                    className="header-text text-center"
                    style={{ fontSize: size && "3rem" }}
                  >
                    Top Industry Trends in 2024
                  </h1>
                  <div className="trendsArea">
                    {content3.map((c) => (
                      <div className="boxOne">
                        <h5 className="text-600">{c.Challenges}</h5>
                        <p className="text-400 mb-0">
                          {c.points.map((p) => (
                            <div>{p}</div>
                          ))}
                        </p>
                      </div>
                    )
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url(${require("assets/images/61769.jpg")})`,
            backgroundSize: size ? "100%" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <Container>
            <Row>
              <Col md={12} xs={12}>
                <div
                  className="text-dark"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 3rem 1rem",
                  }}
                >
                  <h1
                    className="header-text text-center"
                    style={{ fontSize: size && "3rem" }}
                  >
                    Top Industry Challenges
                  </h1>
                  <div className="trendsArea">
                    {content4.map((c) => (
                      <div className="boxOne bg_one">
                        <h5 className="text-600">{c.Industry}</h5>
                        <p className="text-400 mb-0">
                          {c.points.map((p) => (
                            <div>{p}</div>
                          ))}
                        </p>
                      </div>
                    )
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

      </section >

      {/* <section id="speakers">
        <SpeakerCard />
      </section> */}
    </>
  );
}

export default TextGrid;

const content = [
  {
    title: "Banking and Finance.",

    grid: 4,
  },
  {
    title: "Payments and Fintech.",

    grid: 4,
  },
  {
    title: "Credit Cards and Loyalty Programs",

    grid: 4,
  },
  {
    title: "Investment and Venture Capital.",

    grid: 4,
  },
  {
    title: "Regulatory Compliance in Banking.",

    grid: 4,
  },
  {
    title: "Sustainable Finance.",

    grid: 4,
  },
  {
    title: "Fintech and Digital Transformation.",

    grid: 4,
  },
  {
    title: "Wealth Management.",

    grid: 4,
  },
  {
    title: "Wealth Management.",

    grid: 4,
  },
  {
    title: "Venture Capital in Fintech.",

    grid: 4,
  },
  {
    title: "Open Banking and Personalized Finance.",

    grid: 4,
  },
  {
    title: "Tech-Driven Finance.",

    grid: 4,
  },
];

const content2 = [
  {
    Sponsor: "Financial Institutions.",
    grid: 4,
  },
  {
    Sponsor: "Fintech Companies.",
    grid: 4,
  },
  {
    Sponsor: "Consulting Firms.",
    grid: 4,
  },
  {
    Sponsor: "Technology Providers.",
    grid: 4,
  },
  {
    Sponsor: "Investment Firms.",
    grid: 4,
  },
  {
    Sponsor: "Wealth Tech Firms.",
    grid: 4,
  },
  {
    Sponsor: "Law Firms.",
    grid: 4,
  },
  {
    Sponsor: "Legal Lawyers.",
    grid: 4,
  },
];

const content3 = [
  {
    Challenges: "Digital Banking Transformation",
    points: [
      "Digital and cloud-native banking solutions are gaining traction, enhancing operational efficiency and customer experience.",
    ],

    grid: 6,
  },
  {
    Challenges: "Open Banking: ",
    points: [
      "The adoption of open banking frameworks is fostering innovation by allowing third-party developers to build applications and services around financial institutions, improving interoperability.",
    ],

    grid: 6,
  },
  {
    Challenges: "Green Finance and Sustainability: ",
    points: [
      " There is a strong focus on aligning financial activities with environmental sustainability goals and promoting green finance initiatives.",
    ],

    grid: 6,
  },
  {
    Challenges: "Financial Inclusion:",
    points: [
      " Efforts to include the unbanked and underbanked populations are prioritized, leveraging innovative fintech solutions to broaden access to financial ",
    ],

    grid: 6,
  },
  {
    Challenges: "Cybersecurity Enhancements:",
    points: [
      " With the rise of digital transactions, there is an increased focus on cybersecurity measures to protect against fraud, data breaches, and other cyber threats.",
    ],

    grid: 6,
  },
  {
    Challenges: "Artificial Intelligence and Machine Learning: ",
    points: [
      " AI and ML are being integrated into financial services to enhance risk management, customer service, and decision-making processes.",
    ],

    grid: 6,
  },
  {
    Challenges: "Blockchain and Cryptocurrencies: ",
    points: [
      " Blockchain technology and cryptocurrencies are transforming traditional financial systems by offering secure, transparent, and efficient transaction methods.",
    ],

    grid: 6,
  },
  {
    Challenges: "Regulatory Technology (RegTech):  ",
    points: [
      "Advanced regulatory technologies are being adopted to streamline compliance and reporting processes, helping financial institutions manage regulatory requirements more effectively.",
    ],

    grid: 6,
  },
  {
    Challenges: "Payment Innovations: ",
    points: [
      " Innovations in payment technologies, including digital wallets and contactless payments, are making transactions faster and more convenient.",
    ],

    grid: 6,
  },
  {
    Challenges: "FinTech Ecosystem Growth:",
    points: [
      " Supported by Vision 2030, the fintech ecosystem in Saudi Arabia is rapidly expanding, encouraging collaboration and innovation across the industry.",
    ],

    grid: 6,
  },
];

const content4 = [
  {
    Industry: "Interoperability: ",
    points: [
      "Ensuring seamless integration of different FinTech solutions with existing banking and financial systems is critical for operational efficiency and customer satisfaction.",
    ],

    grid: 6,
  },
  {
    Industry: "Access to Funding: ",
    points: [
      " While venture capital investments are growing, many FinTech startups still struggle to secure the necessary funding to scale their operations effectively.",
    ],

    grid: 6,
  },
  {
    Industry: "Innovation and Competition: ",
    points: [
      " Maintaining a competitive edge through continuous innovation while facing competition from both traditional financial institutions and other FinTech startups remains a significant challenge.",
    ],

    grid: 6,
  },
  {
    Industry: "Customer Trust and Adoption: ",
    points: [
      " Building and maintaining consumer trust is essential for the adoption of digital financial services, which involves addressing concerns about security, privacy, and reliability.",
    ],

    grid: 6,
  },
  {
    Industry: "Cost of Digital Transformation:",
    points: [
      " The financial burden associated with transitioning to digital platforms is substantial, especially for traditional banks needing to invest heavily in new technologies.",
    ],

    grid: 6,
  },
  {
    Industry: "Cybersecurity Threats:",
    points: [
      " Beyond data security, the broader spectrum of cybersecurity threats, including fraud, hacking, and identity theft, poses ongoing risks to the industry.",
    ],

    grid: 6,
  },
  {
    Industry: "Regulatory Uncertainty:  ",
    points: [
      "The rapid pace of FinTech innovation often outstrips the development of corresponding regulatory frameworks, leading to uncertainty and increased risk for businesses operating in this space.",
    ],

    grid: 6,
  },
  {
    Industry: "Ethical Governance: ",
    points: [
      " The emergence of economic challenges, climate concerns, and social issues has placed ethical governance at the forefront of responsible FinTech operations.",
    ],

    grid: 6,
  },
];
